const siteConfig = {
  copyright: `Copyright © ${new Date().getFullYear()} INNO&M. All Rights Reserved.`,
  author: {
    name: `Sumin Son`,
    github: `https://github.com/todaypp`,
    linkedin: `https://linkedin.com/sonsumin`,
    email: `clvswft03@gmail.com`,
  },
  seo: {
    title: `이노앤엠`,
    titleTemplate: `%s`,
    description: `마케팅, 디테일의 차이가 결과의 차이. 이노앤엠`,
    siteUrl: `https://innonm.com`,
    twitter: {
      handle: `@innonm`,
      site: `@innonm`,
      cardType: `summary_large_image`,
    },
    openGraph: {
      type: `website`,
      locale: `ko_KR`,
      url: `https://innonm.com`,
      title: `이노앤엠`,
      description: `마케팅, 디테일의 차이가 결과의 차이. 이노앤엠`,
      site_name: `이노앤엠 | 마케팅, 디테일의 차이가 결과의 차이`,
      images: [
        {
          url: `https://innonm.com/og-image.png`,
          width: 1240,
          height: 480,
          alt: `이노앤엠 | 마케팅, 디테일의 차이가 결과의 차이`,
        },
        {
          url: `https://innonm.com/twitter-og-image.png`,
          width: 1012,
          height: 506,
          alt: `이노앤엠 | 마케팅, 디테일의 차이가 결과의 차이`,
        },
      ],
    },
  },
};

export default siteConfig;
