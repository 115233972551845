export type LayerStyles = typeof layerStyles;

const layerStyles = {
  dark: {
    'bg': `gray.700`,
    '.chakra-ui-dark &': {
      bg: `gray.900`,
    },
    'color': `whiteAlpha.900`,
    'borderColor': `whiteAlpha.700`,
  },
  // gray: {
  // },
  light: {
    'bg': `gray.50`,
    '.chakra-ui-dark &': {
      bg: `gray.800`,
    },
  },
  white: {
    'bg': `white`,
    '.chakra-ui-dark &': {
      bg: `gray.700`,
    },
  },
};

export default layerStyles;
