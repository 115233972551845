import 'focus-visible/dist/focus-visible';

import { Styles, mode } from '@chakra-ui/theme-tools';

export type CustomStyles = typeof customStyles;

const customStyles: Styles = {
  global: (props: unknown) => ({
    // This will hide the focus indicator if the element receives focus via the mouse,
    // but it will still show up on keyboard focus.
    '.js-focus-visible :focus:not([data-focus-visible-added])': {
      outline: `none`,
      boxShadow: `none`,
    },
    '*, *::before, ::after': {
      borderColor: `blackAlpha.900`,
    },

    // DEFAULT: default global styles
    'body': {
      'bg': mode(`white`, `gray.800`)(props),
      'color': mode(`gray.700`, `whiteAlpha.900`)(props),
      // 'fontFamily': `body`,
      // 'fontSize': `1.8rem`,
      // 'lineHeight': `base`,
      'textStyle': `content.body`,
      'wordBreak': `keep-all`,

      '.deleted': {
        color: `#ff8383 !important`,
        fontStyle: `normal !important`,
      },

      '.inserted': {
        color: `#b5f4a5 !important`,
        fontStyle: `normal !important`,
      },

      '.slideshow': {
        '.shadow': {
          'position': `absolute`,
          'top': 0,
          'zIndex': 1,

          '&.left': {
            left: 0,
            transform: `rotate(180deg)`,
          },

          '&.right': {
            right: 0,
          },
        },
      },

      '.form': {
        'ml': { md: `0.6vw` },
        'w': `100%`,

        'label': {
          display: `block`,
        },

        '.formRow': {
          'my': `2rem`,
          // justifyContent: 'space-around',
          'justifyContent': `center`,

          '.formCol': {
            'alignItems': `center`,
            'display': `flex`,
            'flexDirection': `column`,
            'mb': { base: `1.5rem`, md: `2rem` },
            'mx': { md: `0.5rem` },
            'px': { md: `1.5rem` },

            '.textareaWrap': {
              wordBreak: `break-all`,
            },
          },
        },

        'input': {
          'backgroundColor': `gray.800`,
          'border': 0,
          'borderBottom': `1px solid white`,
          'color': `white`,
          'maxW': `100%`,
          'mb': `1.75rem`,
          'outline': 0,
          'padding': `0.5rem`,
          'transition': `all 0.1s ease-out`,
          'width': { base: `90vw`, md: `100%` },

          '&:focus': {
            borderBottomColor: `brand.300`,
          },

          "&[type='checkbox']": {
            mr: `0.5rem`,
            width: `unset`,
            maxW: `unset`,
          },
        },

        'select': {
          'backgroundColor': `gray.800`,
          'border': 0,
          'borderBottom': `1px solid white`,
          'color': `white`,
          'maxW': `100%`,
          'mb': `1.75rem`,
          'outline': 0,
          'padding': `0.5rem`,
          'transition': `all 0.1s ease-out`,
          'width': { base: `90vw`, md: `95%` },

          '&:focus': {
            borderBottomColor: `brand.300`,
          },

          "&[type='checkbox']": {
            mr: `0.5rem`,
            width: `unset`,
            maxW: `unset`,
          },
        },
        '.textAreaLabel': {
          color: `gray.400`,
          fontSize: `1.2rem`,
          mt: `0.6rem`,
          position: `relative`,
          right: { base: `37%`, md: `34%` },
        },

        'textarea': {
          'backgroundColor': `gray.800`,
          'border': `1px solid white`,
          'borderRadius': `5px`,
          'color': `white`,
          'maxW': `100%`,
          'mb': `1rem`,
          'mt': `0.5rem`,
          'outline': 0,
          'p': `0 0.5rem`,
          'resize': `none`,
          'transition': `all 0.1s ease-out`,
          'width': { base: `90vw`, md: `95%` },

          '&:focus': {
            borderColor: `brand.300`,
          },

          "&[type='checkbox']": {
            mr: `0.5rem`,
            width: `unset`,
            maxW: `unset`,
          },
        },

        '.checkbox': {
          'alignItems': `flex-start`,
          'display': `flex`,
          'justifyContent': `center`,
          'mr': `15px`,

          //   @include media-breakpoint-down(sm, $grid-breakpoints) {
          //     font-size: css-min(0.9rem, 4.6vw);
          //   }

          'input': {
            margin: `0.46rem 0.6rem`,
          },

          '.link': {
            border: 0,
            mb: `4px`,
          },
        },

        '.buttonWrap': {
          display: `flex`,
          justifyContent: `center`,
          // left: `0.5vw`,
          // position: `relative`,
        },

        "button[type='submit']": {
          'appearance': `button`,
          'background': `transparent`,
          'border': `1px solid`,
          'borderColor': `brand.300`,
          'borderRadius': `2.5rem`,
          'color': `brand.300`,
          'padding': `1.2rem 4.5rem`,
          'transition': `all 0.2s ease-in-out`,

          '&:hover': {
            backgroundColor: `brand.300`,
            color: `white`,
          },
        },

        '.link': {
          color: `unset`,
          fontSize: `unset`,
          fontWeight: `500`,
          padding: `0 0.1rem`,
          textDecoration: `none`,
        },
      },
    },
  }),
};

export default customStyles;
