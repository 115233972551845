const variants = {
  flat: {
    body: {
      gap: `2rem`,
      p: `2rem`,
    },
  },
  rounded: {
    header: {
      borderTopRadius: `2xl`,
    },
    body: {
      borderBottomRadius: `2xl`,
      boxShadow: `xl`,
      p: { base: `2rem`, md: `2rem` },
    },
  },
  smooth: {
    header: {
      borderTopRadius: `lg`,
    },
    body: {
      borderBottomRadius: `lg`,
      boxShadow: `md`,
      p: 6,
    },
  },
};

export type CardVariant = keyof typeof variants;

const Card = {
  parts: [`wrap`, `card`, `header`, `body`],
  baseStyle: (props) => ({
    header: {},
    body: {
      alignItems: `center`,
      bg: props.colorMode === `dark` ? `gray.700` : `white`,
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `center`,
      gap: `1.5rem`,
    },
  }),
  variants,
  defaultProps: {
    variant: `rounded`,
  },
};

export default Card;
