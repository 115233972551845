import { extendTheme } from '@chakra-ui/react';

import * as components from './components';
import customFoundations from './foundations';
import layerStyles from './layer-styles';
import styles from './styles';
import textStyles from './text-styles';

import type { ThemeConfig, ThemeDirection } from '@chakra-ui/react';

const direction: ThemeDirection = `ltr`;

const config: ThemeConfig = {
  cssVarPrefix: `chakra`,
  initialColorMode: `light`,
  useSystemColorMode: false,
};

const customTheme = extendTheme({
  components,
  ...customFoundations,
  config,
  direction,
  layerStyles,
  styles,
  textStyles,
});

export default customTheme;

export type CustomTheme = typeof customTheme;

export const { breakpoints, colors, ...typography } = customFoundations;
