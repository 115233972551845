import 'bootstrap/dist/css/bootstrap.css';
import '@/styles/global.scss';

import { ChakraProvider } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { SSRProvider } from 'react-bootstrap';

import theme from '_/theme';

import FontFace from '@/styles/font-face';
import { getSeo } from '@/utils/seo';

export async function getServerSideProps({ res }) {
  res.setHeader(`Cache-Control`, `public, max-age=0, must-revalidate`);

  return {
    props: {},
  };
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const seo = getSeo();

  return (
    <>
      <Head>
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon.ico?v=2" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://static.cloudflareinsights.com" />
      </Head>
      <DefaultSeo {...seo} />
      <AnimatePresence
        key={`${router.route}-AnimatePresence`}
        exitBeforeEnter
        initial={false}
        // onExitComplete={() => window.scrollTo(0, 0)}
      >
        <ChakraProvider theme={theme}>
          <SSRProvider>
            <Component {...pageProps} key={router.route} />
          </SSRProvider>
        </ChakraProvider>
      </AnimatePresence>
      <FontFace />

      {/* <!-- Mirae Talk Script MemberInfo Start  --> */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            var mi_uk = {
                uk:'',      // 로그인 id
                un:'',      // 로그인 사용자 이름
                ue:'',      // 로그인 사용자 email
                up:''      // 로그인 사용자 연락처
            };
            var mi_is_chat= false;
          `,
        }}
      />
      {/* <!-- Mirae Talk Script MemberInfo End  --> */}
      {/* <!-- 기본적으로 framseset , iframe 구조시 미톡 미실행 --> */}
      {/* <!-- 미실행 되는 영역에서 실행을 원할경우 아래코드 추가 설치 mi_is_chat 값을 true 로 변경 기본 false --> */}
      {/* <script>var mi_is_chat= false;</script> */}
      {/* <!-- Mirae Talk Script Ver 2.0   --> */}
      <script
        type="text/javascript"
        async={true}
        // strategy="lazyOnload"
        src="//log1.toup.net/mirae_log_chat_common.js?adkey=rkyek"
        // TODO: handle legacy code
        // eslint-disable-next-line react/no-unknown-property
        charSet="UTF-8"
        onLoad={(e) => {
          console.log(`Scripted loaded (1): `, e);
        }}
        onError={(e) => {
          console.error(`Script failed to load`, e);
        }}
      />
      {/* <!-- Mirae Talk Script END Ver 2.0   --> */}

      {/*<!-- 공통 적용 스크립트 , 모든 페이지에 노출되도록 설치. 단 전환페이지 설정값보다 항상 하단에 위치해야함 --> */}
      <script
        type="text/javascript"
        async={false}
        // strategy="lazyOnload"
        src="//wcs.naver.net/wcslog.js"
        // eslint-disable-next-line react/no-unknown-property
        charSet="UTF-8"
        onLoad={(e) => {
          console.log(`Scripted loaded (1): `, e);
        }}
        onError={(e) => {
          console.error(`Script failed to load`, e);
        }}
      />
      {/* <script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script> */}

      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            if (!wcs_add) var wcs_add = {};
            wcs_add["wa"] = "s_1512022aecfc";
            if (!_nasa) var _nasa = {};
            if (window.wcs) {
              wcs.inflow("innonm.com");
              wcs_do(_nasa);
            }
          `,
        }}
      />

      {/*
      <script type="text/javascript">
        if (!wcs_add) var wcs_add = {};
        wcs_add["wa"] = "s_1512022aecfc";
        if (!_nasa) var _nasa = {};
        if (window.wcs) {
          wcs.inflow();
          wcs_do(_nasa);
        }
      </script>
      */}
    </>
  );
};

export default App;
