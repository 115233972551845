import { createBreakpoints } from '@chakra-ui/theme-tools';

/**
 * Breakpoints for responsive design
 */
const customBreakpoints = createBreakpoints({
  'sm': `576px`,
  'md': `768px`,
  'lg': `992px`,
  'xl': `1200px`,
  '2xl': `1400px`,
  '3xl': `2000px`,
});

export default customBreakpoints;

// export type CustomBreakpoints = typeof customBreakpoints;
