import { customSpacing } from './spacing';

const customLargeSizes = {
  'max': `max-content`,
  'min': `min-content`,
  'full': `100%`,
  '3xs': `14rem`, // 16 * 14 = 224px
  '2xs': `16rem`, // 16 * 16 = 256px
  'xs': `20rem`, // 16 * 20 = 320px
  'sm': `24rem`, // 16 * 24 = 384px
  'md': `28rem`, // 16 * 28 = 448px
  'lg': `32rem`, // 16 * 32 = 512px
  'xl': `36rem`, // 16 * 36 = 576px
  '2xl': `42rem`, // 16 * 42 = 648px
  '3xl': `48rem`, // 16 * 48 = 768px
  '4xl': `56rem`, // 16 * 56 = 832px
  '5xl': `64rem`, // 16 * 64 = 1024px
  '6xl': `72rem`, // 16 * 72 = 1152px
  '7xl': `80rem`, // 16 * 80 = 1280px
  '8xl': `90rem`, // 16 * 90 = 1440px
};

const customContainer = {
  'sm': `640px`,
  'md': `768px`,
  'lg': `1024px`,
  'xl': `1280px`,
  '2xl': `1440px`,
};

const sizes = {
  ...customSpacing,
  ...customLargeSizes,
  customContainer,
};

/**
 * @deprecated
 * You can derive the Sizes type from the DefaultChakraTheme
 *
 * type Sizes = DefaultChakraTheme['sizes']
 */
// export type Sizes = typeof customSpacing &
//   typeof customLargeSizes & { container: typeof customContainer };

export default sizes;
