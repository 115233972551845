const FontFace = () =>
  // prettier-ignore
  // TODO: handle legacy code
  // eslint-disable-next-line react/no-unknown-property
  <style jsx global>
    {`
      @font-face {
        font-family: 'NanumSquare';
        font-weight: 300;
        src:
          local('NanumSquareL'),
          url('/fonts/NanumSquareL.woff')                                                                 format('woff'),
          url('/fonts/NanumSquareL.ttf')                                                                  format('truetype'),
          url('/fonts/NanumSquareL.otf')                                                                  format('opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareL.woff')         format('woff'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareL.ttf')          format('truetype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareL.eot')          format('embedded-opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareL.eot?#iefix')   format('embedded-opentype');
      }

      @font-face {
        font-family: 'NanumSquare';
        font-weight: 400;
        src:
          local('NanumSquareR'),
          local('NanumSquare'),
          url('/fonts/NanumSquareR.woff')                                                                 format('woff'),
          url('/fonts/NanumSquareR.ttf')                                                                  format('truetype'),
          url('/fonts/NanumSquareR.otf')                                                                  format('opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.woff')         format('woff'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.ttf')          format('truetype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.eot')          format('embedded-opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.eot?#iefix')   format('embedded-opentype');
      }

      @font-face {
        font-family: 'NanumSquare';
        font-weight: 700;
        src:
          local('NanumSquareB'),
          url('/fonts/NanumSquareB.woff')                                                                 format('woff'),
          url('/fonts/NanumSquareB.ttf')                                                                  format('truetype'),
          url('/fonts/NanumSquareB.otf')                                                                  format('opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.woff')         format('woff'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.ttf')          format('truetype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.eot')          format('embedded-opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.eot?#iefix')   format('embedded-opentype');
      }

      @font-face {
        font-family: 'NanumSquare';
        font-weight: 800;
        src:
          local('NanumSquareEB'),
          local('NanumSquare'),
          url('/fonts/NanumSquareEB.woff')                                                                 format('woff'),
          url('/fonts/NanumSquareEB.ttf')                                                                  format('truetype'),
          url('/fonts/NanumSquareEB.otf')                                                                  format('opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.woff')         format('woff'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.ttf')          format('truetype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.eot')          format('embedded-opentype'),
          url('https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.eot?#iefix')   format('embedded-opentype');
      }

      // @font-face {
      //   font-family: 'NanumSquare';
      //   font-weight: 400;
      //   src: local('NanumSquare'), local('NanumSquareR'),
      //     url('/fonts/NanumSquareR.woff') format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.woff)
      //       format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.ttf)
      //       format('truetype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.eot)
      //       format('embedded-opentype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareR.eot?#iefix)
      //       format('embedded-opentype');
      // }

      // @font-face {
      //   font-family: 'NanumSquare';
      //   font-weight: 800;
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.eot);
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.eot?#iefix)
      //       format('embedded-opentype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.woff)
      //       format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareEB.ttf)
      //       format('truetype');
      // }

      // @font-face {
      //   font-family: 'NanumSquareBold';
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.eot);
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.eot?#iefix)
      //       format('embedded-opentype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.woff)
      //       format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquareB.ttf)
      //       format('truetype');
      // }

      // @font-face {
      //   font-family: 'NanumSquareAcR';
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acR.eot);
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acR.eot?#iefix)
      //       format('embedded-opentype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acR.woff)
      //       format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acR.ttf)
      //       format('truetype');
      // }

      // @font-face {
      //   font-family: 'NanumSquareAcL';
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acL.eot);
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acL.eot?#iefix)
      //       format('embedded-opentype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acL.woff)
      //       format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acL.ttf)
      //       format('truetype');
      // }

      // @font-face {
      //   font-family: 'NanumSquareAcEB';
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acEB.eot);
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acEB.eot?#iefix)
      //       format('embedded-opentype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acEB.woff)
      //       format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acEB.ttf)
      //       format('truetype');
      // }

      // @font-face {
      //   font-family: 'NanumSquareAcB';
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acB.eot);
      //   src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acB.eot?#iefix)
      //       format('embedded-opentype'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acB.woff)
      //       format('woff'),
      //     url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquare/NanumSquare_acB.ttf)
      //       format('truetype');
      // }

      // @font-face {
      //   font-family: 'NanumSquare';
      //   font-weight: 300;
      //   src: url(NanumSquareL.eot);
      //   src: local('☺'), url(NanumSquareL.eot?#iefix) format('embedded-opentype'),
      //     url(NanumSquareL.woff) format('woff'), url(NanumSquareL.ttf) format('truetype');
      // }
      // @font-face {
      //   font-family: 'NanumSquare';
      //   font-weight: 400;
      //   src: url(NanumSquareR.eot);
      //   src: local('☺'), url(NanumSquareR.eot?#iefix) format('embedded-opentype'),
      //     url(NanumSquareR.woff) format('woff'), url(NanumSquareR.ttf) format('truetype');
      // }
      // @font-face {
      //   font-family: 'NanumSquare';
      //   font-weight: 700;
      //   src: url(NanumSquareB.eot);
      //   src: local('☺'), url(NanumSquareB.eot?#iefix) format('embedded-opentype'),
      //     url(NanumSquareB.woff) format('woff'), url(NanumSquareB.ttf) format('truetype');
      // }
      // @font-face {
      //   font-family: 'NanumSquare';
      //   font-weight: 800;
      //   src: url(NanumSquareEB.eot);
      //   src: local('☺'), url(NanumSquareEB.eot?#iefix) format('embedded-opentype'),
      //     url(NanumSquareEB.woff) format('woff'),
      //     url(NanumSquareEB.ttf) format('truetype');
      // }

      // /* noto-sans-kr-100 - latin_korean */
      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 100;
      //   src: url('/fonts/noto-sans-kr-v25-latin_korean-100.eot'); /* IE9 Compat Modes */
      //   src: local(''),
      //     url('/fonts/noto-sans-kr-v25-latin_korean-100.eot?#iefix')
      //       format('embedded-opentype'),
      //     /* IE6-IE8 */ url('/fonts/noto-sans-kr-v25-latin_korean-100.woff2')
      //       format('woff2'),
      //     /* Super Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-100.woff')
      //       format('woff'),
      //     /* Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-100.ttf')
      //       format('truetype'),
      //     /* Safari, Android, iOS */
      //       url('/fonts/noto-sans-kr-v25-latin_korean-100.svg#NotoSansKR') format('svg'); /* Legacy iOS */
      // }
      // /* noto-sans-kr-300 - latin_korean */
      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 300;
      //   src: url('/fonts/noto-sans-kr-v25-latin_korean-300.eot'); /* IE9 Compat Modes */
      //   src: local(''),
      //     url('/fonts/noto-sans-kr-v25-latin_korean-300.eot?#iefix')
      //       format('embedded-opentype'),
      //     /* IE6-IE8 */ url('/fonts/noto-sans-kr-v25-latin_korean-300.woff2')
      //       format('woff2'),
      //     /* Super Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-300.woff')
      //       format('woff'),
      //     /* Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-300.ttf')
      //       format('truetype'),
      //     /* Safari, Android, iOS */
      //       url('/fonts/noto-sans-kr-v25-latin_korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
      // }
      // /* noto-sans-kr-regular - latin_korean */
      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 400;
      //   src: url('/fonts/noto-sans-kr-v25-latin_korean-regular.eot'); /* IE9 Compat Modes */
      //   src: local(''),
      //     url('/fonts/noto-sans-kr-v25-latin_korean-regular.eot?#iefix')
      //       format('embedded-opentype'),
      //     /* IE6-IE8 */ url('/fonts/noto-sans-kr-v25-latin_korean-regular.woff2')
      //       format('woff2'),
      //     /* Super Modern Browsers */
      //       url('/fonts/noto-sans-kr-v25-latin_korean-regular.woff') format('woff'),
      //     /* Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-regular.ttf')
      //       format('truetype'),
      //     /* Safari, Android, iOS */
      //       url('/fonts/noto-sans-kr-v25-latin_korean-regular.svg#NotoSansKR')
      //       format('svg'); /* Legacy iOS */
      // }
      // /* noto-sans-kr-500 - latin_korean */
      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 500;
      //   src: url('/fonts/noto-sans-kr-v25-latin_korean-500.eot'); /* IE9 Compat Modes */
      //   src: local(''),
      //     url('/fonts/noto-sans-kr-v25-latin_korean-500.eot?#iefix')
      //       format('embedded-opentype'),
      //     /* IE6-IE8 */ url('/fonts/noto-sans-kr-v25-latin_korean-500.woff2')
      //       format('woff2'),
      //     /* Super Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-500.woff')
      //       format('woff'),
      //     /* Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-500.ttf')
      //       format('truetype'),
      //     /* Safari, Android, iOS */
      //       url('/fonts/noto-sans-kr-v25-latin_korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
      // }
      // /* noto-sans-kr-700 - latin_korean */
      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 700;
      //   src: url('/fonts/noto-sans-kr-v25-latin_korean-700.eot'); /* IE9 Compat Modes */
      //   src: local(''),
      //     url('/fonts/noto-sans-kr-v25-latin_korean-700.eot?#iefix')
      //       format('embedded-opentype'),
      //     /* IE6-IE8 */ url('/fonts/noto-sans-kr-v25-latin_korean-700.woff2')
      //       format('woff2'),
      //     /* Super Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-700.woff')
      //       format('woff'),
      //     /* Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-700.ttf')
      //       format('truetype'),
      //     /* Safari, Android, iOS */
      //       url('/fonts/noto-sans-kr-v25-latin_korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
      // }
      // /* noto-sans-kr-900 - latin_korean */
      // @font-face {
      //   font-family: 'Noto Sans KR';
      //   font-style: normal;
      //   font-weight: 900;
      //   src: url('/fonts/noto-sans-kr-v25-latin_korean-900.eot'); /* IE9 Compat Modes */
      //   src: local(''),
      //     url('/fonts/noto-sans-kr-v25-latin_korean-900.eot?#iefix')
      //       format('embedded-opentype'),
      //     /* IE6-IE8 */ url('/fonts/noto-sans-kr-v25-latin_korean-900.woff2')
      //       format('woff2'),
      //     /* Super Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-900.woff')
      //       format('woff'),
      //     /* Modern Browsers */ url('/fonts/noto-sans-kr-v25-latin_korean-900.ttf')
      //       format('truetype'),
      //     /* Safari, Android, iOS */
      //       url('/fonts/noto-sans-kr-v25-latin_korean-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 100;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 200;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 300;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 400;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 500;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 600;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 700;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 800;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }

      // @font-face {
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 900;
      //   font-display: block;
      //   src: url(/fonts/Inter.woff2) format('woff2');
      // }
    `}
  </style>;

export default FontFace;
