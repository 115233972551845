export type TextStyles = typeof textStyles;

const textStyles = {
  'main-banner': {
    heading: {
      fontFamily: `heading`,
      fontSize: { base: `3rem`, md: `5rem` },
      fontWeight: 900,
      letterSpacing: `tighter`,
      lineHeight: `1.25`,
    },
    description: {
      fontFamily: `body`,
      fontSize: { base: `1.15rem`, md: `1.3rem` },
      fontWeight: `bold`,
    },
  },
  'navbar': {
    'color': `gray.700`,
    'fontFamily': `body`,
    'fontSize': { base: `1.25rem`, md: `1.3rem` },
    'fontWeight': `normal`,
    '.chakra-ui-dark &': { color: `gray.200` },
  },
  'navbar-sub': {
    fontFamily: `body`,
    fontSize: { base: `1.1rem`, md: `1.2rem` },
    fontWeight: `normal`,
  },
  'content': {
    'title': {
      fontFamily: `heading`,
      fontSize: { base: `1.6rem`, md: `2rem` },
      fontWeight: `bolder`,
      opacity: `0.95`,
    },
    'title-md': {
      fontSize: { base: `1.4rem`, md: `1.6rem` },
      fontWeight: `bolder`,
      opacity: `0.95`,
    },
    'title-sm': {
      color: `gray.600`,
      fontSize: { base: `1.1rem`, md: `1.4rem` },
      fontWeight: `bolder`,
      // opacity: `0.95`,
    },
    'title-xs': {
      fontSize: { base: `1.05rem`, md: `1.1rem` },
      fontWeight: `bolder`,
      opacity: `0.9`,
    },
    'body': {
      fontFamily: `body`,
      fontSize: { base: `1.1rem`, md: `1.2rem` },
      fontWeight: `bold`,
      lineHeight: `base`,
      opacity: `0.9`,
    },
    'body-lg': {
      fontFamily: `body`,
      fontSize: { base: `1.1rem`, md: `1.25rem` },
      fontWeight: `bold`,
      lineHeight: `base`,
      opacity: `0.8`,
    },
    'body-md': {
      fontFamily: `body`,
      fontSize: { base: `1rem`, md: `1.1rem` },
      fontWeight: `bold`,
      lineHeight: `base`,
      opacity: `0.6`,
    },
    'body-sm': {
      fontFamily: `body`,
      fontSize: { base: `1rem`, md: `1.1rem` },
      fontWeight: `normal`,
      lineHeight: `base`,
    },
  },
  'heading': {
    fontFamily: `heading`,
    fontSize: { base: `2.3rem`, md: `3.8rem` },
    fontWeight: `bolder`,
    letterSpacing: `-0.015em`,
    lineHeight: `1.24`,
    textAlign: `center`,
  },
  'heading-2': {
    fontFamily: `heading`,
    textAlign: `center`,
    fontWeight: `bold`,
    letterSpacing: `-0.015em`,
    lineHeight: `1.24`,
    fontSize: { base: `1.75rem`, md: `3rem` },
  },
  'heading-sm': {
    fontFamily: `heading`,
    textAlign: `center`,
    fontWeight: `bolder`,
    letterSpacing: `-0.015em`,
    lineHeight: `1.24`,
    fontSize: { base: `1.75rem`, md: `3rem` },
  },
  'description': {
    fontFamily: `body`,
    fontSize: { base: `1.15rem`, md: `1.25rem` },
    fontWeight: `bold`,
    lineHeight: `1.4`,
    opacity: 0.7,
  },
  'caps': {
    textTransform: `uppercase`,
    fontSize: `sm`,
    letterSpacing: `widest`,
    fontWeight: `bold`,
  },
};

export default textStyles;
