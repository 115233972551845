import customBlur from './blur';
import customBorders from './borders';
import customBreakpoints from './breakpoints';
import customColors from './colors';
import customRadii from './radius';
import customShadows from './shadows';
import customSizes from './sizes';
import { customSpacing } from './spacing';
import customTransition from './transition';
import customTypography from './typography';
import customZIndices from './z-index';

const customFoundations = {
  ...customTypography,
  blur: customBlur,
  borders: customBorders,
  breakpoints: customBreakpoints,
  colors: customColors,
  radii: customRadii,
  shadows: customShadows,
  sizes: customSizes,
  space: customSpacing,
  transition: customTransition,
  zIndices: customZIndices,
};

export default customFoundations;

// export type CustomFoundations = typeof customFoundations;
