import { theme as baseTheme } from '@chakra-ui/react';

const customColors = {
  ...baseTheme.colors,

  brand: baseTheme.colors.blue,
  bg: {
    gray: `rgb(239, 239, 239)`,
  },
  activeLink: {
    default: `brand.500`,
    _dark: `brand.400`,
  },
  primary: {
    default: `brand.300`,
    _dark: `brand.200`,
  },
  secondary: {
    default: `brand.500`,
    _dark: `brand.400`,
  },
  error: `red.500`,
  success: `green.500`,
  warning: `yellow.500`,
  info: `cyan.500`,
};

export default customColors;

// export type CustomColor = typeof customColors;
