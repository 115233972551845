const sizes = {
  sm: {
    fontSize: `1.8rem`,
  },
  md: {
    fontSize: `2.3rem`,
  },
};

export type ContentTitleSize = keyof typeof sizes;

const ContentTitle = {
  baseStyle: (props) => ({
    color: props.colorMode === `dark` ? `gray.200` : `gray.700`,
  }),
  sizes,
};

export default ContentTitle;
